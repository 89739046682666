import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useConfig } from "../../contexts/configContext"
import useHttpApi from "../useHttpApi"

function KPI(props) {
  const [cvasCounters, setcvasCounters] = useState(null)
  const [pvasCounters, setpvasCounters] = useState(null)
  const [domainTable, setdomainTable] = useState(null)
  const [tdr, settdr] = useState(null)
  const [tdrCounters, settdrCounters] = useState(null)
  const [routingTable, setroutingTable] = useState(null)
  const [domainsInRoutes, setdomainsInRoutes] = useState(null)

  const config = useConfig()
  const httpApi = useHttpApi()

  useEffect(() => {
    props.setBreadcrumb("", "KPI", "")
  }, [])

  useEffect(() => {
    fetchCounters().then((res) => {
      setcvasCounters(res.cvas)
      setpvasCounters(res.pvas)
    })

    fetchDomainTable().then((res) => {
      setdomainTable(res)
    })

    fetchTDR().then((res) => {
      settdr(res)
    })

    fetchRoutingTable().then((res) => setroutingTable(res))

    setInterval(
      () =>
        fetchCounters().then((res) => {
          setcvasCounters(res.cvas)
          setpvasCounters(res.pvas)
        }),
      5000
    )
  }, [])

  useEffect(() => {
    domainTable && routingTable && findDomainsInRoutingTable()
  }, [domainTable, routingTable])

  const fetchCounters = async () => {
    let cvas = await httpApi.callGetAPI({ url: `h_json/cvas_counters` })
    let pvas = await httpApi.callGetAPI({ url: `h_json/pvas_counters` })
    return { cvas, pvas }
  }

  const fetchDomainTable = async () => {
    return await httpApi.callGetAPI({ url: `json/domaintable` })
  }

  const fetchRoutingTable = async () => {
    return await httpApi.callGetAPI({ url: `json/routing` })
  }

  const fetchTDR = async () => {
    return await httpApi.callGetAPI({ url: `list_json/tdr_repo` })
  }

  const resetCounters = async () => {
    await httpApi.callDeleteAPI({ url: `h_reset/cvas_counters` })
    await httpApi.callDeleteAPI({ url: `h_reset/pvas_counters` })

    fetchCounters().then((res) => {
      setcvasCounters(res.cvas)
      setpvasCounters(res.pvas)
    })
  }

  const downloadCounters = () => {
    let vasObject = { pvas: pvasCounters, cvas: cvasCounters }
    console.log(vasObject)

    const blob = new Blob([JSON.stringify(vasObject, null, 2)], { type: "application/json" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = "vasCounters.json"
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const findDomainsInRoutingTable = () => {
    let _domainInRoutes = []
    for (let route of routingTable) {
      let found = false
      for (let domain of domainTable) {
        if (route.domain_name === domain.domain_name) {
          _domainInRoutes = [..._domainInRoutes, domain]
          found = true
          break
        }
      }
      if (found) continue
    }
    setdomainsInRoutes(_domainInRoutes)
  }

  useEffect(() => {
    if (domainsInRoutes && tdr) {
      let _tdrCounters = domainsInRoutes.map((d) => {
        return { dns: d.dns, counter: 0 }
      })
      tdr.forEach((_tdr) => {
        _tdrCounters.forEach((tdrc) => {
          if (_tdr.apiroot.includes(tdrc.dns)) {
            tdrc.counter++
          }
        })
      })
      _tdrCounters = _tdrCounters.filter((t) => t.counter > 0)
      settdrCounters(_tdrCounters)
    }
  }, [domainsInRoutes, tdr])

  return (
    <>
      {/* <Spinner show={config.loading} /> */}
      {config.loading ? null : (
        <>
          <div className="page">
            <h5 className="title mt-5">Inbound Global Counters</h5>
            <div className="data-row">
              <span>Total:</span>
              <span>{cvasCounters?.incoming || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Filtered:</span>
              <span>{cvasCounters?.filtered || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Manipulated:</span>
              <span>{cvasCounters?.manipulation || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Handshake Sent:</span>
              <span>{cvasCounters?.handshake_req_sent || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Handshake Successful:</span>
              <span>{cvasCounters?.handshake_req_success || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Handshake Failed:</span>
              <span>{cvasCounters?.handshake_req_failed || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Handshake Not Answered:</span>
              <span>{cvasCounters?.handshake_req_timeout || 0}</span>
            </div>
            <div className="separator"></div>
            <h5 className="title mt-5">Outbound Global Counters</h5>
            <div className="data-row">
              <span>Total:</span>
              <span>{pvasCounters?.incoming || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Filtered:</span>
              <span>{pvasCounters?.filtered || 0}</span>
            </div>
            <div className="separator"></div>

            <div className="data-row">
              <span>Manipulated:</span>
              <span>{pvasCounters?.manipulation || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Handshake Received:</span>
              <span>{pvasCounters?.handshake_req_sent || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Handshake Successful:</span>
              <span>{pvasCounters?.handshake_req_success || 0}</span>
            </div>
            <div className="separator"></div>
            <div className="data-row">
              <span>Handshake Failed:</span>
              <span>{pvasCounters?.handshake_req_failed || 0}</span>
            </div>
            <div style={{ float: "right", marginRight: "1.5%" }}>
              <Button onClick={() => downloadCounters()}>DOWNLOAD</Button>
              <Button onClick={() => resetCounters()} style={{ marginLeft: "20px" }}>
                RESET ALL
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default KPI
